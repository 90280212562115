var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-3"},[_c('Breadcrumb',{staticClass:"m-0"},[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'EducatorClassesList'
          }}},[_vm._v(" "+_vm._s(_vm.$t("TeacherCourses.Classes Management"))+" ")])],1),_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'TeacherCourseDetail',
            params: {
              id: _vm.$route.params.id
            }
          }}},[_vm._v(" "+_vm._s(_vm.myTitle)+" ")])],1),_c('el-breadcrumb-item',[_vm._v(" Edit ")])],1)],2),_c('el-skeleton',{staticClass:"mt-3",attrs:{"loading":!_vm.myTitle,"animated":""}},[_c('template',{slot:"template"},[_c('el-skeleton-item',{staticStyle:{"line-height":"1.5","width":"50%"}})],1),[_c('Heading',{attrs:{"heading":"h4","content":`Edit - ${_vm.myTitle}`}})]],2),_c('hr'),_c('el-menu',{staticClass:"course-menu mb-3 mt-3",attrs:{"default-active":_vm.activeSection,"mode":"horizontal","background-color":"#f3f3f3","active-text-color":_vm.themeColor},on:{"select":_vm.showSection}},[_c('el-menu-item',{attrs:{"index":"EducatorClassInfoEdit"}},[_c('i',{staticClass:"el-icon-s-home"}),_vm._v(" Edit Information ")]),_c('el-menu-item',{attrs:{"index":"EducatorClassRosterEdit"}},[_c('i',{staticClass:"el-icon-user-solid"}),_vm._v(" Edit Roster ")]),_c('el-menu-item',{attrs:{"index":"EducatorClassMaterialsEdit"}},[_c('i',{staticClass:"el-icon-user-solid"}),_vm._v(" Edit Materials ")]),(false)?_c('el-menu-item',{attrs:{"index":"lessons"}},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" Lessons ")]):_vm._e()],1),_c('div',{staticClass:"mb-4"},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }